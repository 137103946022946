<script setup>
import { useRouter } from "vue-router";

const props = defineProps({
    params: Object
});

const router = useRouter();

/**
 * Need to add delete component as well.
 */

const handleEditAction = () => {
    const editUrl = `${props.params.editUrl}/${props.params.data.id}/edit`;
    router.push(editUrl);
}
</script>

<template>
    <div class="d-flex">
        <v-btn v-if="params.showEdit" icon="mdi-pencil" variant="text" @click="handleEditAction()"></v-btn>
    </div>
</template>
